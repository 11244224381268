import { TemplateMergeFieldCategory } from '@deltasierra/frontend/graphql';

export const mergeTags: Array<
    { tag: string, categories: TemplateMergeFieldCategory[] }
> = [
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_address_city' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_address_street' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_email' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_name' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_phone_number' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_postal_code' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'location_state' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'to_first_name' },
    { categories: [TemplateMergeFieldCategory.Clubready], tag: 'to_last_name' },
    { categories: [TemplateMergeFieldCategory.Other], tag: 'current_year' },
];