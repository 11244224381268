import { useState } from 'react';
import { ToggleCollapse, ToggleCollapseProps } from './ToggleCollapse';

export type ToggleCollapseContainerProps = Omit<ToggleCollapseProps, 'handleSetOpen' | 'isOpen' | 'onClickTitle'> & {
    isOpenDefault?: boolean;
    titleOpens?: boolean;
};

export const ToggleCollapseContainer = ({
    children,
    isDisabled,
    isOpenDefault = false,
    title,
    titleOpens,
}: ToggleCollapseContainerProps): JSX.Element => {
    const [open, setOpen] = useState(isOpenDefault);

    const toggle = () => setOpen(prev => !prev);

    return (
        <ToggleCollapse
            handleSetOpen={toggle}
            isDisabled={isDisabled}
            isOpen={open}
            title={title}
            onClickTitle={titleOpens ? toggle : undefined}
        >
            {children}
        </ToggleCollapse>
    );
};

ToggleCollapseContainer.displayName = 'ToggleCollapseContainer';
