/* eslint-disable @typescript-eslint/no-extra-parens */
import { XCircle } from '@deltasierra/react/icons';
import {
    Autocomplete,
    AutocompleteProps,
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    createFilterOptions,
} from '@mui/material';
import { TextField } from './TextField';
import { AutocompleteWithChipsOption } from './types';

const SELECT_ALL_ID = 'select-all';

export type AutocompleteMultiselectProps<T extends string> = AutocompleteProps<
    AutocompleteWithChipsOption<T>,
    true,
    false,
    true
>;

export type AutocompleteWithChipsProps<T extends string> = Pick<
    AutocompleteMultiselectProps<T>,
    'freeSolo' | 'loading' | 'loadingText' | 'onChange' | 'options' | 'value'
> & {
    type: T;
    label: string;
    progressAriaLabel?: string;
    selectAllText: string;
    showLoading?: boolean;
};

const allSelected = (
    options: ReadonlyArray<AutocompleteWithChipsOption>,
    values: ReadonlyArray<AutocompleteWithChipsOption> | undefined,
): boolean => {
    if (!values) {
        return false;
    }
    const valueIds = new Set(values.map(value => (typeof value === 'string' ? value : value.id)));
    const optionIds = options.map(option => (typeof option === 'string' ? option : option.id));
    return optionIds.every(id => valueIds.has(id));
};

// eslint-disable-next-line max-lines-per-function
export const AutocompleteWithChips = <T extends string>({
    freeSolo,
    label,
    loading,
    loadingText,
    onChange,
    options,
    progressAriaLabel,
    selectAllText,
    showLoading,
    type,
    value,
}: AutocompleteWithChipsProps<T>): JSX.Element => (
    <Autocomplete
        clearIcon={null}
        clearOnBlur
        disableCloseOnSelect
        filterOptions={(preFilteredOptions, params) => {
            const filter = createFilterOptions({
                stringify: (option: AutocompleteWithChipsOption<T>) =>
                    typeof option === 'string' ? option : option.title,
            });
            const filtered = filter(
                preFilteredOptions.length > 0
                    ? [{ __typename: type, id: SELECT_ALL_ID + label, title: selectAllText }, ...preFilteredOptions]
                    : preFilteredOptions,
                params,
            );
            return filtered;
        }}
        freeSolo={freeSolo}
        getLimitTagsText={more => <Chip label={`+${more} ${label.toLowerCase()}`} variant="filled" />}
        getOptionLabel={option => (typeof option === 'string' ? option : option.title)}
        handleHomeEndKeys
        isOptionEqualToValue={(option, testValue) =>
            (typeof option === 'string' ? option : option.title) ===
            (typeof testValue === 'string' ? testValue : testValue.title)
        }
        limitTags={25}
        loading={loading}
        loadingText={loadingText}
        multiple
        options={options}
        renderInput={({ InputLabelProps, ...params }) => (
            <TextField
                {...params}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </>
                    ),
                }}
                label={label}
                variant="outlined"
            />
        )}
        renderOption={(props, option, { selected }) => (
            <li
                {...props}
                aria-selected={selected || allSelected(options, value)}
                key={typeof option === 'string' ? option : option.id}
            >
                <Checkbox
                    checked={selected || allSelected(options, value)}
                    key={typeof option === 'string' ? option : option.id}
                    sx={{ marginRight: 8 }}
                />
                {typeof option === 'string' ? option : option.title}
            </li>
        )}
        renderTags={(tags, getTagProps) =>
            tags.map((tag, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                    <Chip
                        key={key}
                        label={typeof tag === 'string' ? tag : tag.title}
                        sx={{
                            '&:hover': {
                                '.loading': {
                                    display: 'none',
                                },
                            },
                            '&:not(:hover)': {
                                '.delete': {
                                    display: 'none',
                                },
                            },
                        }}
                        variant="filled"
                        {...tagProps}
                        deleteIcon={
                            showLoading && typeof tag === 'string' ? (
                                <Box alignItems="center" display="flex" width="0.9em">
                                    <CircularProgress
                                        aria-label={progressAriaLabel}
                                        className="loading"
                                        color="inherit"
                                        size="0.8em"
                                    />
                                    <XCircle className="delete" color="inherit" />
                                </Box>
                            ) : (
                                <Box alignItems="center" display="flex" width="0.9em">
                                    <XCircle color="inherit" />
                                </Box>
                            )
                        }
                    />
                );
            })
        }
        sx={{ paddingTop: '1rem' }}
        value={value}
        onChange={(event, selected, reason, detail) => {
            if (typeof detail?.option !== 'string' && detail?.option.id === SELECT_ALL_ID + label) {
                if (!allSelected(options, value)) {
                    onChange?.(event, [...new Set([...options, ...(value ?? [])])], reason);
                } else {
                    onChange?.(event, [], reason);
                }
            } else if (reason === 'createOption') {
                const existingOptionIndex = options.findIndex(
                    option => (typeof option === 'string' ? null : option.title) === detail?.option,
                );
                if (existingOptionIndex > -1) {
                    onChange?.(event, [...new Set([...(value ?? []), options[existingOptionIndex]])], reason);
                } else {
                    onChange?.(event, selected, reason);
                }
            } else {
                onChange?.(event, selected, reason);
            }
        }}
    />
);
AutocompleteWithChips.displayName = 'AutocompleteWithChips';
