export function triggerStringToFileDownload(text: string, fileType: string, fileName: string): void {
    const blob = new Blob([text], { type: fileType });

    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = URL.createObjectURL(blob);
    anchor.dataset.downloadurl = [fileType, anchor.download, anchor.href].join(':');
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setTimeout(() => {
        URL.revokeObjectURL(anchor.href);
    }, 1500);
}
