export const AUTOCOMPLETE_NULL_GROUP_NAME = '_null_';

type AutocompleteWithGroupsOptionWithoutGroup = {
    groupName?: null;
};

type AutocompleteWithGroupsOptionWithGroup = {
    groupName: string;
};

export type AutocompleteWithGroupsOption = {
    disabled?: boolean;
    groupName?: string | null;
    id: string;
    title: string;
} & (AutocompleteWithGroupsOptionWithGroup | AutocompleteWithGroupsOptionWithoutGroup);
