import { ReactNode, useState, useEffect } from 'react';

export type DelayRenderProps = { children: ReactNode; waitBeforeShow?: number };

// eslint-disable-next-line jsdoc/require-param
/**
 * @deprecated Using this without cause will earn you the dunce hat
 */
export const DelayRender = ({ children, waitBeforeShow = 500 }: DelayRenderProps): JSX.Element => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    if (!isShown) {
        return <></>;
    }
    return <>{children}</>;
};

DelayRender.displayName = 'DelayRender';
