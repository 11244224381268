import { Box, Button, ButtonProps, Menu, MenuItem } from '@mui/material';
import { Key, MouseEvent, ReactNode, useState } from 'react';

export type ButtonMenuOption<T> = {
    id: T;
    value: ReactNode | string;
};

export type ButtonMenuProps<T extends Key | string = Key> = {
    buttonTitle: ReactNode;
    disabled: boolean;
    onClick: (id: T) => void;
    options?: Array<ButtonMenuOption<T>>;
    startIcon?: ButtonProps['startIcon'];
};

export const ButtonMenu = <T extends Key | string = Key>({
    buttonTitle,
    disabled,
    onClick,
    options,
    startIcon,
}: ButtonMenuProps<T>): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (id?: T) => {
        setAnchorEl(null);
        if (id) {
            onClick(id);
        }
    };

    return (
        <Box>
            <Button
                disableElevation
                disabled={disabled}
                size="small"
                startIcon={startIcon}
                sx={{
                    paddingY: '0.5rem',
                }}
                variant="outlined"
                onClick={handleClick}
            >
                {buttonTitle}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                elevation={0}
                open={open}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                onClose={() => handleClose()}
            >
                {options?.map(option => (
                    <MenuItem
                        disableRipple
                        key={option.id}
                        sx={{
                            '> svg': {
                                color: 'primary.main',
                                marginRight: '0.5rem',
                            },
                            color: 'primary.main',
                            minWidth: '10rem',
                        }}
                        onClick={() => handleClose(option.id)}
                    >
                        {option.value}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

ButtonMenu.displayName = 'ButtonMenu';
