import { Translations } from '@deltasierra/translations/react';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

type ShouldPreventNavigationCheckFunc = () => boolean;

function getHackyName(originalName: string): string {
    return `${originalName}_hack`;
}

function useInterceptAppRouter<TMethod extends keyof AppRouterInstance>(
    original: TMethod,
    interceptFn: (original: string, ...args: Parameters<AppRouterInstance[TMethod]>) => void,
): void {
    const appRouter = useRouter();

    useEffect(() => {
        if (!appRouter) {
            throw new Error('useInterceptAppRouter must be used within an App Router context');
        }
        const originalMethod = appRouter[original];

        (appRouter as AppRouterInstance & { [key: string]: any })[getHackyName(original)] = originalMethod;

        appRouter[original] = ((...args: Parameters<AppRouterInstance[TMethod]>) =>
            interceptFn(original, ...args)) as AppRouterInstance[TMethod];

        return () => {
            appRouter[original] = originalMethod;
        };
    }, [appRouter, original, interceptFn]);
}

function useOverrideTransition(handleTransition: (original: string, ...args: any[]) => void) {
    useInterceptAppRouter('back', handleTransition);
    useInterceptAppRouter('forward', handleTransition);
    useInterceptAppRouter('push', handleTransition);
    useInterceptAppRouter('refresh', handleTransition);
    useInterceptAppRouter('replace', handleTransition);
}

type TemporaryNextCallType = {
    originalMethodName: string;
    args: unknown[];
};

export function usePreventNavigation(
    checkNavigation: ShouldPreventNavigationCheckFunc,
    t: Translations<'EmailBuilderTemplate'>,
): void {
    const appRouter = useRouter();

    const callOriginalFunc = useCallback(
        (originalMethodName: string, args: unknown[]) =>
            (appRouter as AppRouterInstance & { [key: string]: any })[getHackyName(originalMethodName)](...args),
        [appRouter],
    );

    const [nextCall, setNextCall] = useState<TemporaryNextCallType | null>(null);

    const handleViewTransition = useCallback(
        (originalMethodName: string, ...args: any[]) => {
            const shouldPreventNavigation = checkNavigation();
            if (!shouldPreventNavigation) {
                return callOriginalFunc(originalMethodName, args);
            }
            setNextCall({ args, originalMethodName });
            // This needs to be returning a truthy unused value
            return 'unused';
        },
        [checkNavigation, callOriginalFunc],
    );

    useOverrideTransition(handleViewTransition);

    useEffect(() => {
        // We need to do this first to prevent automatically unloading
        if (nextCall) {
            // eslint-disable-next-line no-alert
            const wantToNavigate = window.confirm(
                t('There are unsaved changes, do you want to navigate away and lose these changes?'),
            );
            if (wantToNavigate) {
                return callOriginalFunc(nextCall.originalMethodName, nextCall.args);
            } else {
                setNextCall(null);
            }
        }

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const shouldPreventNavigation = checkNavigation();
            if (shouldPreventNavigation) {
                event.preventDefault();
                // This is to support legacy browsers
                event.returnValue = t(
                    'There are unsaved changes, do you want to navigate away and lose these changes?',
                );
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [checkNavigation, nextCall, callOriginalFunc, t]);
}
