import { AutocompleteRenderGroupParams, Box, Checkbox, ListItemButton } from '@mui/material';
import { ToggleCollapseContainer } from './ToggleCollapseContainer';
import { AutocompleteWithGroupsGroupData } from './types';

type OnClickGroupGroupInfo = {
    groupId: string;
    groupName: string;
};

export type AutocompleteWithGroupsGroupProps = AutocompleteRenderGroupParams & {
    groupData?: Map<string, AutocompleteWithGroupsGroupData>;
    onClickGroup?: (group: OnClickGroupGroupInfo, newValue: boolean) => void;
};

export function AutocompleteWithGroupsGroup({
    children,
    group,
    groupData,
    onClickGroup,
}: AutocompleteWithGroupsGroupProps): JSX.Element {
    const groupInfo = groupData?.get(group);
    if (groupInfo) {
        const checked = groupInfo.state === 'checked' || groupInfo.state === 'indeterminate';
        const indeterminate = groupInfo.state === 'indeterminate';
        return (
            <li>
                <ToggleCollapseContainer
                    title={
                        <ListItemButton>
                            <Checkbox
                                checked={checked}
                                disableRipple
                                indeterminate={indeterminate}
                                onClick={event => {
                                    event.stopPropagation();
                                    onClickGroup?.(
                                        { groupId: groupInfo.groupId, groupName: group },
                                        groupInfo.state === 'unchecked' || groupInfo.state === 'indeterminate',
                                    );
                                }}
                            />
                            {group}
                        </ListItemButton>
                    }
                    titleOpens
                >
                    <ul>
                        <Box
                            sx={{
                                '& .MuiCheckbox-root': {
                                    marginRight: 8,
                                },
                            }}
                        >
                            {children}
                        </Box>
                    </ul>
                </ToggleCollapseContainer>
            </li>
        );
    } else {
        return <>{children}</>;
    }
}
AutocompleteWithGroupsGroup.displayName = 'AutocompleteWithGroupsGroup';
