import { ArrowDropDownIcon } from '@deltasierra/react/icons';
import { Box, Checkbox, Collapse, Stack } from '@mui/material';
import { ReactNode } from 'react';

export type ToggleCollapseProps = {
    children?: ReactNode;
    handleSetOpen: () => void;
    isDisabled?: boolean;
    isOpen: boolean;
    onClickTitle?: () => void;
    title: ReactNode;
};

export const ToggleCollapse = ({
    children,
    handleSetOpen,
    isDisabled,
    isOpen,
    onClickTitle,
    title,
}: ToggleCollapseProps): JSX.Element => {
    if (isDisabled) {
        return (
            <Stack>
                {title}
                {children}
            </Stack>
        );
    }
    return (
        <Stack>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
                onClick={onClickTitle}
            >
                {title}
                <Checkbox
                    checked={isOpen}
                    checkedIcon={<ArrowDropDownIcon />}
                    icon={<ArrowDropDownIcon style={{ rotate: '270deg' }} />}
                    inputProps={{
                        'aria-label': 'Toggle collapse checkbox',
                    }}
                    onClick={event => {
                        event.stopPropagation();
                        handleSetOpen();
                    }}
                />
            </Box>
            <Collapse in={isOpen}>{children}</Collapse>
        </Stack>
    );
};

ToggleCollapse.displayName = 'ToggleCollapse';
