import {
    Autocomplete,
    Checkbox,
    ListItemButton,
    AutocompleteProps as MuiAutocompleteProps,
    createFilterOptions,
} from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import { AutocompleteWithGroupsChips } from './AutocompleteWithGroupsChips';
import { AutocompleteWithGroupsGroup, AutocompleteWithGroupsGroupProps } from './AutocompleteWithGroupsGroup';
import { TextField } from './TextField';
import { AUTOCOMPLETE_NULL_GROUP_NAME, AutocompleteWithGroupsGroupData, AutocompleteWithGroupsOption } from './types';

type AutocompleteWithGroupsOptionChange = {
    reason: 'removeOption' | 'selectOption';
    optionId: string;
};

type AutocompleteWithGroupsGroupChange = {
    reason: 'clickGroup';
    groupId: string;
    newValue: boolean;
};

type AutocompleteWithGroupsClearChange = {
    reason: 'clear';
};

type AutocompleteWithGroupsChangeData = {
    event?: SyntheticEvent;
    value: AutocompleteWithGroupsOption[];
} & (AutocompleteWithGroupsClearChange | AutocompleteWithGroupsGroupChange | AutocompleteWithGroupsOptionChange);

type MuiAutocompleteWithGroupsProps = MuiAutocompleteProps<AutocompleteWithGroupsOption, true, true, false>;

export type AutocompleteWithGroupsProps = Pick<MuiAutocompleteWithGroupsProps, 'options' | 'value'> & {
    groupData?: Map<string, AutocompleteWithGroupsGroupData>;
    label?: string;
    onChange?: (change: AutocompleteWithGroupsChangeData) => void;
};

const filterOptions = createFilterOptions<AutocompleteWithGroupsOption>({
    stringify: option => `${option.title}${option.groupName}`,
});

export function AutocompleteWithGroups({
    groupData,
    label,
    onChange: onBetterChange,
    options,
    value,
}: AutocompleteWithGroupsProps): JSX.Element {
    const onClickGroup = useCallback<Exclude<AutocompleteWithGroupsGroupProps['onClickGroup'], undefined>>(
        ({ groupId, groupName }, newValue) => {
            const newValueWithoutExisting = value?.filter(val => val.groupName !== groupName) ?? [];
            onBetterChange?.({
                groupId,
                newValue,
                reason: 'clickGroup',
                value: newValue
                    ? // If the newValue is checked then add in all the group items
                      [...newValueWithoutExisting, ...options.filter(opt => opt.groupName === groupName)]
                    : // If the newValue is unchecked, remove all the group items from the value
                      newValueWithoutExisting,
            });
        },
        [onBetterChange, value, options],
    );
    const onChange = useCallback<Exclude<MuiAutocompleteWithGroupsProps['onChange'], undefined>>(
        (event, newValue, reason, details) => {
            if (!onBetterChange) {
                return;
            }
            if ((reason === 'selectOption' || reason === 'removeOption') && details) {
                onBetterChange({
                    event,
                    optionId: details.option.id,
                    reason,
                    value: newValue,
                });
            } else if (reason === 'clear') {
                onBetterChange({
                    event,
                    reason: 'clear',
                    value: newValue,
                });
            }
        },
        [onBetterChange],
    );
    return (
        <Autocomplete
            clearOnBlur
            disableCloseOnSelect
            filterOptions={filterOptions}
            fullWidth
            getOptionLabel={option => option.title}
            groupBy={option => option.groupName ?? AUTOCOMPLETE_NULL_GROUP_NAME}
            handleHomeEndKeys
            isOptionEqualToValue={(option, testOption) => option.id === testOption.id}
            multiple
            options={options}
            renderGroup={({ children, group, key }) => (
                <AutocompleteWithGroupsGroup group={group} groupData={groupData} key={key} onClickGroup={onClickGroup}>
                    {children}
                </AutocompleteWithGroupsGroup>
            )}
            renderInput={({ InputLabelProps, ...params }) => <TextField {...params} label={label} variant="outlined" />}
            renderOption={(props, option, { selected }) => (
                <ListItemButton
                    component="li"
                    {...props}
                    aria-selected={selected}
                    disabled={option.disabled}
                    key={option.id}
                >
                    <Checkbox checked={selected} disableRipple disabled={option.disabled} key={option.id} />
                    {option.title}
                </ListItemButton>
            )}
            renderTags={val => <AutocompleteWithGroupsChips groupData={groupData} value={val} />}
            value={value}
            onChange={onChange}
        />
    );
}

AutocompleteWithGroups.displayName = 'AutocompleteWithGroups';
