/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
'use client';

import { useLazyQuery } from '@apollo/client';
import {
    GET_CLIENT_MERGE_FIELDS,
    GET_LOCATION_CLIENT,
    GET_LOCATION_MERGE_FIELDS,
    GetClientMergeFieldsQuery,
    GetClientMergeFieldsQueryVariables,
    GetLocationClientQuery,
    GetLocationClientQueryVariables,
    GetLocationMergeFieldsQuery,
    GetLocationMergeFieldsQueryVariables,
    TemplateMergeFieldCategory,
} from '@deltasierra/frontend/graphql';
import { mergeTags } from '@deltasierra/features/buildable-template/shared';
import { TemplateMergeFieldCategory as MergeFieldCategory } from '@deltasierra/features/merge-fields/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MergeFieldOption } from './types';

type UseMergeFieldOptions = {
    clientId?: string | null;
    featureFlag?: boolean;
    isEnabledMergeTags: boolean;
    locationId?: string | null;
};

const ordering = {
    [MergeFieldCategory.SOCIAL]: 0,
    [MergeFieldCategory.CLUBREADY]: 1,
    [MergeFieldCategory.LOCATION]: 2,
    [MergeFieldCategory.CUSTOM]: 3,
    [MergeFieldCategory.OTHER]: 4,
}

export const useMergeFields = ({
    clientId,
    featureFlag,
    isEnabledMergeTags,
    locationId,
}: UseMergeFieldOptions): {
    error: Error | null;
    loading: boolean;
    mergeFieldOptions: MergeFieldOption[];
} => {
    const [mergeFields, setMergeFields] = useState<Array<{
        categories: TemplateMergeFieldCategory[];
        field: string;
    }>>([]);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState(false);
    const [getClientMergeFieldsQuery] = useLazyQuery<GetClientMergeFieldsQuery, GetClientMergeFieldsQueryVariables>(
        GET_CLIENT_MERGE_FIELDS,
    );
    const [getLocationClientQuery] = useLazyQuery<GetLocationClientQuery, GetLocationClientQueryVariables>(
        GET_LOCATION_CLIENT,
    );
    const [getLocationMergeFieldsQuery] = useLazyQuery<
        GetLocationMergeFieldsQuery,
        GetLocationMergeFieldsQueryVariables
    >(GET_LOCATION_MERGE_FIELDS);

    const getClientMergeFields = useCallback(
        async (id: string): Promise<Array<{ categories: TemplateMergeFieldCategory[]; field: string }>> => {
            const result = await getClientMergeFieldsQuery({
                variables: {
                    id,
                },
            });
            if (
                result.data?.client?.buildableTemplateMergeFields &&
                result.data?.client?.buildableTemplateMergeFields.length > 0
            ) {
                return result.data?.client?.buildableTemplateMergeFields
                    .filter(
                        mergeField =>
                            mergeField.__typename === 'ClientBuildableTemplateMergeFieldGeneric' && mergeField.enabled,
                    )
                    .map(enabledMergeField => ({
                        categories:
                            enabledMergeField.__typename === 'ClientBuildableTemplateMergeFieldGeneric'
                                ? enabledMergeField.categories
                                : [],
                        field: enabledMergeField.field,
                    }));
            }
            return [];
        },
        [getClientMergeFieldsQuery],
    );

    const getLocationClient = useCallback(
        async (id: string): Promise<string | undefined> => {
            const { data } = await getLocationClientQuery({ variables: { locationId: id } });

            return data?.location?.client.id;
        },
        [getLocationClientQuery],
    );

    const getLocationMergeFields = useCallback(
        async (
            id: string,
            clientFields: Array<{ categories: TemplateMergeFieldCategory[]; field: string }>,
        ): Promise<Array<{ categories: TemplateMergeFieldCategory[]; field: string }>> => {
            const result = await getLocationMergeFieldsQuery({
                variables: {
                    id,
                    input: {
                        fields: clientFields.map(clientField => clientField.field),
                    },
                },
            });
            if (
                result.data?.location?.buildableTemplateMergeFields &&
                result.data?.location?.buildableTemplateMergeFields.length > 0
            ) {
                return result.data.location?.buildableTemplateMergeFields.map(mergeField => ({
                    categories: mergeField.categories,
                    field: mergeField.field,
                }));
            }
            return [];
        },
        [getLocationMergeFieldsQuery],
    );

    const fetchData = useCallback(async () => {
        try {
            setError(null);
            setLoading(true);

            if (clientId && !locationId) {
                const clientMergeFields = await getClientMergeFields(clientId);
                setMergeFields(clientMergeFields);
            } else if (locationId && clientId) {
                const clientMergeFields = await getClientMergeFields(clientId);
                const locationMergeFields = await getLocationMergeFields(locationId, clientMergeFields);

                setMergeFields(locationMergeFields);
            } else if (locationId && !clientId) {
                const locationClientId = await getLocationClient(locationId);

                if (!locationClientId) {
                    throw new Error('Could not fetch client id for location id');
                }

                const clientMergeFields = await getClientMergeFields(locationClientId);
                const locationMergeFields = await getLocationMergeFields(locationId, clientMergeFields);

                setMergeFields(locationMergeFields);
            }
        } catch (err) {
            setMergeFields([])
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, [clientId, getClientMergeFields, getLocationClient, getLocationMergeFields, locationId]);

      useEffect(() => {
        if (featureFlag && (clientId || locationId)) {
            fetchData();
        }
      }, [clientId, featureFlag, fetchData, locationId]);

      const mergeFieldOptions = useMemo(() => {
        const options: MergeFieldOption[] = [];
        Object.entries(TemplateMergeFieldCategory).forEach(([_key, value]) => {
            /**
             * The isEnabledMergeTags variable is used at this point to determine whether to include the
             * hardcoded merge "tags" in the merge field options list. Once all merge fields have been
             * moved to the new backend structure, this variable can be reviewed and removed.
             */
            const filteredMergeTags = (isEnabledMergeTags ? mergeTags : [])
                .filter(tag => tag.categories.includes(value))
                .map(filteredTag => filteredTag.tag);
            const filteredMergeFields = mergeFields
                .filter(field => field.categories.includes(value))
                .map(filteredField => filteredField.field);
            const option = {
                category: MergeFieldCategory[value],
                mergeFields: [...filteredMergeFields, ...filteredMergeTags].sort(),
            };
            if (option.mergeFields.length) {
                options.push(option)
            }
        });
        return options.sort(
            // eslint-disable-next-line id-length
            (a, b) => ordering[a.category] - ordering[b.category] || a.category.localeCompare(b.category),
        );
      }, [isEnabledMergeTags, mergeFields]);

    return {
        error,
        loading,
        mergeFieldOptions,
    };
};
