/* eslint-disable max-len */
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { ChipExtended } from './ChipExtended';
import { AutocompleteWithGroupsGroupData, AutocompleteWithGroupsOption } from './types';

type GroupChipValue = {
    type: 'group';
    group: string;
    selectedSize?: number;
    selectedTitles: string[];
    totalSize: number;
};

type SingleChipValue = {
    type: 'single';
    title: string;
};

type ChipValue = GroupChipValue | SingleChipValue;

export type AutocompleteWithGroupsChipProps = {
    value: AutocompleteWithGroupsOption[];
    groupData?: Map<string, AutocompleteWithGroupsGroupData>;
};

export function AutocompleteWithGroupsChips({ groupData, value }: AutocompleteWithGroupsChipProps): JSX.Element {
    const chipValues = useMemo(
        (): ChipValue[] => [
            ...[
                ...value
                    .filter((val): val is typeof val & { groupName: string } => !!val.groupName)
                    .reduce((acc, opt) => {
                        const curr = acc.get(opt.groupName);
                        acc.set(opt.groupName, {
                            count: (curr?.count ?? 0) + 1,
                            groupName: opt.groupName,
                            groupSize: 0,
                            selectedTitles: [...curr?.selectedTitles ?? [], opt.title],
                        });
                        return acc;
                    }, new Map<string, { count: number; groupName: string; selectedTitles: string[]; groupSize: number }>())
                    .values(),
            ].map(
                (groupItem): GroupChipValue => ({
                    group: groupItem.groupName,
                    selectedSize:
                        groupData && groupItem.count === groupData.get(groupItem.groupName)?.totalItems
                            ? undefined
                            : groupItem.count,
                    selectedTitles: groupItem.selectedTitles,
                    totalSize: groupItem.groupSize,
                    type: 'group',
                }),
            ),
            ...value
                .filter(val => !val.groupName)
                .map(
                    (opt): SingleChipValue => ({
                        title: opt.title,
                        type: 'single',
                    }),
                ),
        ],
        [groupData, value],
    );

    return (
        <>
            {chipValues.map(chipVal => (
                <Box
                    key={chipVal.type === 'group' ? chipVal.group : chipVal.title}
                    sx={{
                        margin: 0.3,
                    }}
                >
                    <ChipExtended
                        count={chipVal.type === 'group' ? chipVal.selectedSize : undefined}
                        label={chipVal.type === 'group' ? chipVal.group : chipVal.title}
                        tooltip={chipVal.type === 'group' ? chipVal.selectedTitles.join(', ') : undefined}
                        variant="filled"
                    />
                </Box>
            ))}
        </>
    );
}
AutocompleteWithGroupsChips.displayName = 'AutocompleteWithGroupsChips';
