import { graphql } from '../../__graphqlTypes';

export const GET_CLIENT_MERGE_FIELDS = graphql(`
    query GetClientMergeFields($id: ID!) {
        client(id: $id) {
            id
            buildableTemplateMergeFields {
                ... on ClientBuildableTemplateMergeField {
                    field
                }
                # For now we have only implemented generic merge fields
                ... on ClientBuildableTemplateMergeFieldGeneric {
                    categories
                    enabled
                }
            }
        }
    }
`);
